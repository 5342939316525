import React, {FC} from 'react';
// material ui
import {makeStyles, AppBar, Toolbar, IconButton, Typography} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
// icons
import {Menu as MenuIcon} from '@material-ui/icons';
import cookie from 'js-cookie';
import {useParams} from 'react-router-dom';
import AuthService from 'src/services/AuthService';
import { useAuth0 } from "@auth0/auth0-react";
import { MenuStore } from 'src/store/store';
import $ from 'jquery'
// export const MenuContext = React.createContext()

const authService = new AuthService();


const NavigationBar: FC = () => {
  const history = useHistory();
  const { logout } = useAuth0();
  const {company_id, document_id} = useParams();
  const [selectedTab, setSelectedTab] = React.useState(null);
  const handleLogout = () => {
    cookie.remove('accessToken');
    cookie.remove('username');
    // logout({ returnTo: window.location.origin })
    authService.logout();
    history.push('/auth/signin');
  };
  MenuStore.subscribe(() => {
    const va = MenuStore.getState()['value'];
    setSelectedTab(va['selectedTab']);
  });
  // console.log(selectedTab)
  function pdftoggle(){
  if($('.RightSideBar').css('display') == 'none'){
    $('.RightSideBar').show();

  }else {
    $('.RightSideBar').hide();

  }
  }
  return (
    <header className="alpha-header">
      <div className="container-fluid">
        <div className="d-flex flex-wrap align-items-center">
          <div className="brand-bar mr-15 mr-lg-20 mr-lg-70">
            <a className="logo" href="/">
              <img src="/images/logo.svg" alt="logo" />
            </a>
          </div>
          <div className="search-bar media-body mr-15 mr-lg-20 mr-lg-40">
            {/* <h1 className="fs-company">AI-WORKSPACE</h1> */}
            <div>
              <img className="sandbox-logo" alt='sandbox-logo' src="/images/sandbox-logo.png" />
            </div>
          </div>
          <div className="right-bar d-flex flex-wrap align-items-center">
            {selectedTab && (selectedTab['label']=="Template Mapping" ||  selectedTab['label']=="Financial Statement" || selectedTab['label']=="Coface Mapping") ?(
          <ul className="icons-nav d-flex align-items-center mr-15 mr-lg-20">
              
                <li className="ah-item">
                  <a className="ah-link get-pdf" href="#" onClick={pdftoggle} data-toggle="tooltip" title="View Pdf" >
                    <img src="/images/icons/pdf-file.png" alt="View Pdf" />
                  </a>
                </li>
                
             
              </ul>):''}
              <ul className="d-flex align-items-center">
                
                <li className="ah-item dropdown">
                  <a
                    className="ah-link dropdown-toggle"
                    id="ahbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img className="avatar" src="/images/avatar.png" alt="" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="ahbarDropdown">
                    <a className="dropdown-item">My Profile</a>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
        </div>
        
      </div>
    </header>
  );
};

export default NavigationBar;
