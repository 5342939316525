
export const BACKEND_API = process.env.REACT_APP_BACKEND_API_API;

export const SANBOX_API = `${process.env.REACT_APP_UDP_QA}api/v1/AlphaStream/SandBox/`;

// export const AUTH_LOGIN = '/api/auth/login';
export const AUTH_LOGIN = `${BACKEND_API}api/login/`;
export const GET_USER = `${BACKEND_API}api/users/me/`;
export const CREATE_USER = `${BACKEND_API}api/users/`;
export const LOG_OUT = `${BACKEND_API}api/logout/`;

export const QA_ENGINE_API = process.env.REACT_APP_QA_ENGINE_API;
export const EXTRACTION_ENGINE = process.env.REACT_APP_EXTRACTION_ENGINE_API;
export const PDF_MASK =  process.env.REACT_APP_PDF_MASK_API;
export const CV_API = `${process.env.REACT_APP_DAL_CV}extract`
// export const CV_API = `http://10.10.2.78:7013/extract`
export const CV_ESTIMATE = `${process.env.REACT_APP_DAL_CV}estimate`
// export const CV_ESTIMATE = `http://10.10.2.78:7013/estimate`
export const DAL_API = process.env.REACT_APP_DAL_API
export const SENTIMENT_API = `${process.env.REACT_APP_SENTIMENT}sentiment`
export const SUMMARIZE_API = `http://14.98.3.230:10012/extract/all`
export const SUMMARIZE_API1 = `http://14.98.3.230:8903/chatgpt`
export const DAL_COMPANY_SEARCH = (page: any, text: any,clientid: any,active: any) => `${DAL_API}UiReq/FindCompany?page=${page}&companyName=${text}&clientId=${clientid}&activeOnly=${active}&companyIsin=${text}`
export const DAL_GET_TEMPLATE_DATA = (companyId: any) => `${DAL_API}AlphaStream/SegmentBuilder/SegmentTemplate?companyId=${companyId}`
export const DAL_SAVE_TEMPLATE_DATA = ``
export const DAL_DOCUMENTS = (companyid: any, page: any, size: any) => `${DAL_API}UiReq/Documents?companyId=${companyid}&page=${page}&customPageSize=${size}&isFactStream=${true}&filterStage1=true&filterStage2=true`
export const TEMPLATE_MAP_FETCH = `${process.env.REACT_APP_TEMPLATE_MAP}extract`
export const TEMPLATE_MAP_UPDATE = `${process.env.REACT_APP_TEMPLATE_MAP}update`
export const TEMPLATE_MAP_DATA_BLOCK = `${SANBOX_API}GetDataItemsAndBlocks`
export const GET_PARSED_DATA = `${process.env.REACT_APP_PARSED_DATA}`;
export const ER_PARSER_API = `${process.env.REACT_APP_ER_API}api/pcq`
export const ER_PARSER_ESTIMATE = `${process.env.REACT_APP_ER_API}generate_paragraph`
export const ER_PARSER_FILE_PROCESS = `${process.env.REACT_APP_ER_API}process_paragraph`
export const ER_PARSER_COMPANYLIST = `${process.env.REACT_APP_ER_API}getCompanies`
export const ER_PARSER_COMPANYDOCS = `${process.env.REACT_APP_ER_API}getCompanyDocuments`
export const ER_PARSER_DOCS_URL = `${process.env.REACT_APP_ER_API}getUrl`
export const TEMPLATE_XML_PARSE = `${process.env.REACT_APP_COFACE_XML}xmlparse`
export const COFACE_RECALCULATE = `${process.env.REACT_APP_RECALCULATE}`
export const TEMPLATE_MAP_UPLOAD_SYNONYMS = `${SANBOX_API}UploadSynonyms`

export const TABLECLF_COMPANIES = `${process.env.REACT_APP_TABLECLF}getCompanies`
export const TABLECLF_DOCUMENTS =  `${process.env.REACT_APP_TABLECLF}getCompanyDocuments`
export const TABLECLF_EXTRACT = `${process.env.REACT_APP_TABLECLF}extract`
export const TABLECLF_RECLASSIFY = `${process.env.REACT_APP_TABLECLF}reclassify`
export const TABLECLF_COMMIT = `${process.env.REACT_APP_TABLECLF}commit`
export const GET_NORMALIZED_DATA = `${process.env.REACT_APP_NORMALISED_API}`;
export const GET_TABLES_DATA = `${process.env.REACT_APP_TABLES_DATA}`;
export const GET_ANALYST_DATA2 =  `${process.env.REACT_APP_ANALYST_API2}`;
export const NTA = process.env.REACT_APP_NTA
export const GET_TABLE_NTA_DATA = process.env.REACT_APP_GET_TABLE_NTA_DATA
export const GET_SECTIONS = process.env.REACT_APP_GET_SECTIONS
export const DAL_COMPANY_COMPANY = (page: any, text: any,clientid: any,active: any) => `${process.env.REACT_APP_DAL_COMPANY_COMPANY}?page=${page}&companyName=${text}&activeOnly=${active}&companyIsin=${text}`
export const GET_DOCUMENTS = (companyid: any, page: any, size: any) => `${process.env.REACT_APP_GET_DOCUMENTS}?companyId=${companyid}&page=${page}&product=Fundamentals&customPageSize=${size}&isFactStream=${true}&filterStage1=true&filterStage2=true`
export const COVENANT_PARSER = process.env.REACT_APP_COVENANT_PARSER
export const COVENANT_PARSER_PDF_BASE = `http://164.52.211.233:8096/CovennatsStream/`
export const CORPORATE_STRUCTURE_BASE = `${process.env.REACT_APP_CORPORATE_STRUCTURE_BASE}`
export const CORPORATE_STRUCTURE = `${process.env.REACT_APP_CORPORATE_STRUCTURE_BASE}/corporateStructure`
export const FUND_PARSER_ESTIMATE = `${process.env.REACT_APP_FUND_PARSER}/estimate`
export const FUND_PARSER_EXTRACT = `${process.env.REACT_APP_FUND_PARSER}/extract`
export const KYC_EXTRACT_API = `http://208.51.60.2:7014/extract`
export const FORM_PARSER_API = `http://208.51.60.2:7015/extract`

export const ESG_LINEITEM_API = `http://38.17.50.26:8902/textClassify`

export const ASSIGN_TAG = `http://43.242.38.17:9001/assigntag`
export const ALPHAMASTER_KM = `http://43.242.38.17:9001/km`

export const FINANCIAL_LLM = `http://13.127.237.179:9901/rrAPI`;
export const FINANCIAL_LLM_PDF = `http://13.127.237.179:9902`