import React from 'react';
// Router Imports
import {Route, Redirect} from 'react-router-dom';
// Redux imports
// cookie
import cookie from 'js-cookie';
import { SiteLayout } from './layouts';

function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route
    {...rest}
    render={(props) =>
      cookie.get('accessToken') ? (
        <SiteLayout>
          <Component {...props} />
        </SiteLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/auth/signin',
            state: {from: props.location},
          }}
        />
      )
    }
  />
  );
}

export default PrivateRoute;
