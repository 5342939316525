import React, {FC} from 'react';

// layouts
import {BasicLayout, DrawerLayout} from 'src/layouts';
import {useLocation} from 'react-router-dom';
import 'src/styles/App.scss';
import '@kenshooui/react-multi-select/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select/dist/js/bootstrap-select.min.js';
import 'font-awesome/css/font-awesome.min.css';

const SiteLayout: FC = ({children}) => {
  const router = useLocation();
  // const router = useRouter();

  // if (
  //   router.pathname.startsWith('/dashboard') ||
  //   router.pathname.startsWith('/create') ||
  //   router.pathname.startsWith('/company')
  // ) {
  //   return <DrawerLayout>{children}</DrawerLayout>;
  // }

  return (
    <>
     
      <BasicLayout>{children}</BasicLayout>
    </>
  );
};

export default SiteLayout;
