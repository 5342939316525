import React, {FC} from 'react';
// components
import {NavigationBar} from 'src/components/toolbars';

export interface IBasicLayoutProps {
  children: React.ReactNode;
}

const BasicLayout: FC<IBasicLayoutProps> = ({children}) => {
  return (
    // style={{backgroundColor: '#F4F9FE'}}
    <div style={{backgroundColor: '#f0f2f5'}}>
      <NavigationBar />
      <main>{children}</main>
    </div>
  );
};

export default BasicLayout;
