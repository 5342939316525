import React from 'react';
// Router Imports
import {Router, Route, Switch, useHistory} from 'react-router-dom';
import history from 'src/lib/history';
// components
import { Loading } from 'src/components/fallBack';
// import HomePage from './pages/Home';
const HomePage = React.lazy(() => import('./pages/Home'));
import PrivateRoute from './PrivateRoute';
import cookie from 'js-cookie';
// import SignIn from 'src/pages/auth/signin';
const SignIn = React.lazy(() => import('src/pages/auth/signin'));
// import Home from 'src/pages/index';
import {SiteLayout} from 'src/layouts';
// import PdfViewer from './components/contents/PdfViewer';
const PdfViewer = React.lazy(() => import('./components/contents/PdfViewer'));
// import Synonyms from './pages/Synonyms';
const Synonyms = React.lazy(() => import('./pages/Synonyms'));
// import 'src/styles/App.scss';
// import '@kenshooui/react-multi-select/dist/style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import 'bootstrap-select/dist/css/bootstrap-select.min.css';
// import 'bootstrap-select/dist/js/bootstrap-select.min.js';
// import '../node_modules/font-awesome/css/font-awesome.min.css';
import AuthRoute from './AuthRoute';

function App() {
  /**
   * Similar to componentDidMount and componentDidUpdate:
   * Checking for the user token availability and changing the isAuthenticated flag value.
   */

  const location = useHistory();
  const [socket_, setSocket_] = React.useState(null);
  React.useEffect(() => {
    if (!cookie.get('accessToken') && !cookie.get('username')) {
      location.push('/auth/signin');
    }
  });
  // React.useEffect(() => {
  //   cookie.set('username', 'admin');
  //   cookie.set('accessToken', '233444');
  //   if (!cookie.get('accessToken') && !cookie.get('username')) {
  //     location.push('/auth/signin');
  //   }
  // });
  // Rending Routes
  return (
    <React.Suspense fallback={<Loading/>}>
    <Router history={history}>
      <Switch>
        <AuthRoute path="/auth/signin" exact component={SignIn} />
        <PrivateRoute path="/" exact component={HomePage} />
        <PrivateRoute path="/popout/pdf" exact component={PdfViewer} />
        <PrivateRoute path="/synonyms" exact component={Synonyms} />
      </Switch>
    </Router>
    </React.Suspense>
  );
}

export default App;
