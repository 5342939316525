import React, {FC} from 'react';
// clsx
import clsx from 'clsx';
// next
import {Link} from 'react-router-dom';
// router
// import Router from 'next/router';
import {useHistory} from 'react-router-dom';
// services
import AuthService from 'src/services/AuthService';
import SettingsIcon from '@material-ui/icons/Settings';


// cookie
import cookie from 'js-cookie';
// material ui
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  createStyles,
  Theme,
  Box,
  Menu,
  MenuItem,
  Badge,
  InputBase,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
} from '@material-ui/core';
import {fade} from '@material-ui/core/styles';
// import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
  Home as HomeIcon,
  CheckCircle,
} from '@material-ui/icons';

const drawerWidth = 240;
const authService = new AuthService();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    search: {
      padding: '0 12px',
      position: 'relative',
      minHeight: '50px',
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      width: '100%',
      borderLeft: '1px solid rgba(255,255,255,0.2)',
      [theme.breakpoints.up('sm')]: {
        marginLeft: 'auto',
        width: 'auto',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '8ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      marginLeft: 'auto',
      width: 'auto',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    sectionMobile: {
      display: 'flex',
      marginLeft: 'auto',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menuItem: {fontSize: '14px'},
  })
);

export interface IDashboardHeaderProps {
  handleDrawerOpen?: () => void;
  open?: boolean;
}

const DashboardHeader: FC<IDashboardHeaderProps> = (props) => {
  // props speading
  const {open} = props;

  const [userName, setuserName] = React.useState(null);


  const [showSuccess, setShowSuccess] = React.useState(false);

  const history = useHistory();
  const classes = useStyles();
  const menuId = 'primary-search-account-menu';

  React.useEffect(() => {
    if (cookie.get('accessToken') && cookie.get('username')) {
      setuserName(cookie.get('username'));
    } else {
      // Router.push('/auth/signin');
      history.push('/auth/signin');
    }
  });
  const handleMenu = (event: any, setState) => {
    setState(event.currentTarget);
  };
  const handleClose = (setState) => {
    setState(null);
  };
  const handleLogout = () => {
    cookie.remove('accessToken');
    authService.logout();
    history.push('/auth/signin');
  };

  const SaveAndFiling = (data: any) => {
    console.log(data);
  };

  const handleModalClose = (setState, value) => {
    setState(value);
  };

  const handleCancel = (setState, value) => {
    setShowSuccess(false);
    setState(value);
  };


  return (
    <AppBar
      position="fixed"
      // className={clsx(classes.appBar, {
      //   [classes.appBarShift]: open,
      // })}
      >
      <header className="alpha-header">
        <div className="container-fluid">
          <div className="d-flex flex-wrap align-items-center">
            <div className="brand-bar mr-15 mr-lg-20 mr-lg-70">
              <a className="logo" href="/">
                <img src="/images/logo.svg" alt="" />
              </a>
            </div>
            <div className="search-bar mr-15 mr-lg-20 mr-lg-40">
            <h1 className="fs-company">AI-WORKSPACE</h1>
            </div>
          
          </div>
        </div>
        
      </header>
    </AppBar>
  );
};

export default DashboardHeader;
