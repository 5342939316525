export function Loading() {
  return (
    <section className="loading" id="loading">
      <span className="loadWords">Loading...</span>
      <span className="loading__anim"></span>
    </section>
  );
}

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <div>
        Something wrong!
        <button
          onClick={() => {
            resetErrorBoundary();
          }}
          className="ml-2"
        >
          Try again
        </button>
        <p className="text-red-500">{error.message}</p>
      </div>
    );
  };
