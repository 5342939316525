import * as actionTypes from './actionTypes';

const initialState_menustate: MenuCurrentState = {
  value: {
    selectedTab: null
  },
};
export const reducer_Tab = (
    state: MenuCurrentState = initialState_menustate,
    action: MenuAction
  ): MenuCurrentState => {
    switch (action.type) {
      case actionTypes.ADD_SELECTED_TAB: {
        const tabval: MenuCurrentValue = {
          selectedTab: action.value.selectedTab
        };
        return {
          ...state,
          value: tabval,
        };
      }
      case actionTypes.REMOVE_SELECTED_TAB: {
        const tabval: MenuCurrentValue = {
          selectedTab: null
        };
        return {
          ...state,
          value: tabval,
        };
      }
    }
    return state;
  };